var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "n-page",
    [
      _c(
        "a-row",
        { attrs: { type: "flex" } },
        [
          _c(
            "a-col",
            { staticClass: "gx-text-right", attrs: { span: 24 } },
            [
              _c(
                "a-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.openClubSearch },
                },
                [_vm._v("Join a new Club")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "a-row",
        { attrs: { type: "flex" } },
        [
          _vm.user && _vm.user.user
            ? _c(
                "a-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "a-card",
                    {
                      staticClass: "gx-card-table-full",
                      attrs: { title: "Club Management" },
                    },
                    [
                      _c("my-clubs-table", {
                        ref: "member_club_listing",
                        attrs: { "member-id": parseInt(_vm.user.user.id) },
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _c("search-clubs-modal", {
        attrs: { visible: _vm.clubSearchModal },
        on: { close: _vm.closeClubSearch },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }